var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block md:inline-block md:static",class:{ relative: !_vm.externalShow },attrs:{"role":"menu","tabindex":"0"},on:{"focusin":function($event){$event.stopPropagation();$event.preventDefault();return _vm.expandHover.apply(null, arguments)},"focusout":function($event){$event.stopPropagation();$event.preventDefault();return _vm.collapseHover.apply(null, arguments)},"mouseenter":function($event){$event.stopPropagation();$event.preventDefault();return _vm.expandHover.apply(null, arguments)},"mouseleave":function($event){$event.stopPropagation();$event.preventDefault();return _vm.collapseHover.apply(null, arguments)}}},[_c('div',{ref:"airportsLabel",staticClass:"airports-label",class:{
      'airports-label--collapse': !_vm.show,
      'hidden md:block': _vm.externalShow,
    },attrs:{"role":"button","tabindex":"0"},on:{"keypress":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggle.apply(null, arguments)},"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggle.apply(null, arguments)}}},[_c('div',{staticClass:"airports-label__default flex items-center"},[_vm._v("\n      "+_vm._s(_vm.$i18n("general.airports"))+"\n      "),_c('IconCaretRight',{staticClass:"text-black-alt-500 md:hidden ml-auto"}),_vm._v(" "),_c('div',{staticClass:"caret hidden md:block ml-1"})],1),_vm._v(" "),_c('div',{staticClass:"airports-label__back flex items-center text-base"},[_c('IconCaretRight',{staticClass:"text-black-alt-500 rotate-180 transform mr-3"}),_vm._v("\n      "+_vm._s(_vm.$i18n("templates.go-to-home"))+"\n    ")],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show || _vm.externalShow),expression:"show || externalShow"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.hide),expression:"hide"}],ref:"scroller",staticClass:"mega-menu text-black absolute inset-x-0 cursor-default z-50 md:top-23 md:shadow-section",class:{
      'mega-menu--half-scrolled': _vm.halfScrolled,
      'inset-0 md:inset-y-auto bg-white md:hidden': _vm.externalShow,
    }},[_c('div',{staticClass:"text-base",class:{ 'filtering-country': _vm.filteringCountry }},[(_vm.unfilteredCountries.length > 1)?_c('div',{staticClass:"bg-silver-100 hidden md:block"},[_c('div',{staticClass:"md:container md:pt-7 pb-3 md:px-10"},[_c('SearchInput',{staticClass:"mega-menu__search relative",attrs:{"expression":_vm.searchExpression},on:{"expressionChanged":function($event){_vm.searchExpression = $event}}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.externalShow && _vm.countries.length),expression:"!externalShow && countries.length"}],staticClass:"mt-3 hidden sm:flex py-4 mega-menu__countries flex justify-start font-bold select-none flex-wrap -mx-5 leading-relaxed"},[_c('div',{staticClass:"mega-menu__countries__country cursor-pointer px-5 text-black-alt-800",class:{
                'mega-menu__countries__country--highlight': !_vm.filteringCountry,
              },attrs:{"role":"button","tabindex":"0"},on:{"keypress":function($event){$event.stopPropagation();$event.preventDefault();_vm.filterCountry = null},"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.filteringCountry = null}}},[_vm._v("\n              "+_vm._s(_vm.$i18n("general.all-countries"))+"\n            ")]),_vm._v(" "),_vm._l((_vm.countries),function(country){return _c('div',{key:country},[(_vm.countries.length > 1)?_c('div',{staticClass:"mega-menu__countries__country cursor-pointer px-5 text-black-alt-800",class:{
                  'mega-menu__countries__country--highlight':
                    _vm.filteringCountry === country,
                },attrs:{"role":"button","tabindex":"0"},on:{"keypress":function($event){$event.stopPropagation();$event.preventDefault();return _vm.filterCountry(country)},"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.filterCountry(country)}}},[_vm._v("\n                "+_vm._s(country)+"\n              ")]):_vm._e()])})],2)],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"bg-white select-none",class:_vm.countries.length > 1 ? 'md:pt-3 md:pb-10 ' : 'md:py-10'},[(!_vm.countries.length)?_c('div',{staticClass:"px-7 md:px-10 text-center flex place-content-center mt-8"},[_vm._v("\n          "+_vm._s(_vm.$i18n("customer.no-results"))+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"md:container md:px-10 mega-menu__grid place-content-between md:gap-y-4",class:{
            'md:grid': !_vm.filteringCountry,
            'md:grid-cols-4': !_vm.filteringCountry && !_vm.isUSDomain,
            'md:grid-cols-1': !_vm.filteringCountry && _vm.isUSDomain,
          }},[_vm._l((_vm.countries),function(country){return [(_vm.countryAirportsFiltered[country].length)?[_c('div',{key:country,staticClass:"mega-menu__grid__country",class:{
                  grid: _vm.filteringCountry || _vm.isUSDomain,
                  'md:grid-cols-1': _vm.columns === 1 && !_vm.isUSDomain,
                  'md:grid-cols-2': _vm.columns === 2 && !_vm.isUSDomain,
                  'md:grid-cols-3': _vm.columns === 3 || _vm.isUSDomain,
                  'md:grid-cols-4': _vm.columns === 4 && !_vm.isUSDomain,
                }},[(_vm.countries.length > 1)?_c('div',{staticClass:"mega-menu__grid__country__title",class:{
                    'mega-menu__grid__country__title--full':
                      _vm.filteringCountry || _vm.isUSDomain,
                  }},[_vm._v("\n                  "+_vm._s(country)+"\n                ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.countryAirportsFiltered[country]),function(airport){return [_c('div',{key:airport.id,staticClass:"mega-menu__grid__airport md:my-1 md:pr-2"},[_c('a',{staticClass:"hover:text-orange-500 hover:no-underline flex items-center px-5 md:px-0 py-3 md:py-0 border-b border-athens md:border-none",class:{
                        'text-orange-500':
                          _vm.selectedAirportByPicker.id === airport.id,
                        'text-black-alt-500':
                          _vm.selectedAirportByPicker.id !== airport.id,
                      },attrs:{"href":`/${airport.slug}/`},on:{"click":_vm.clear}},[_c('div',{staticClass:"w-full pr-2 md:pr-0 whitespace-nowrap overflow-hidden text-ellipsis overflow-ellipsis"},[_vm._v("\n                        "+_vm._s(airport.name)+"\n                      ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"text-black-alt-500 md:hidden ml-auto transform scale-75"})],1)])]})],2)]:_vm._e()]})],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }